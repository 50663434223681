import { configure, defineRule } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import en_json from '@/locales/en.json'
import es_json from '@/locales/es.json'
import pt_json from '@/locales/pt.json'
import { setLocale } from '@vee-validate/i18n'
import { required } from '@vee-validate/rules'
import { isValidEmail } from '@/utils/isValidEmail'
import { isValidName } from '@/utils/isValidName'
import { isValidUrl } from '@/utils/isValidUrl'

defineRule('required', required)
defineRule('email', isValidEmail)
defineRule('name', isValidName)
defineRule('link', isValidUrl)

configure({
  generateMessage: localize({
    en: {
      messages: {
        ...en_json.validation,
      },
    },
    es: {
      messages: {
        ...es_json.validation,
      },
    },
    pt: {
      messages: {
        ...pt_json.validation,
      },
    },
  }),
})

setLocale('pt')
